<template>
  <div class="elevators-component">
    <div class="map-wrap">
      <div
        class="map-mode-btn"
        @click="satellite = !satellite"
      >
        <img v-if="satellite" class="map-img" src="../assets/map.png" alt="filters">
        <img v-else class="map-img" src="../assets/satellite.png" alt="filters">
        <span v-if="satellite" class="map-title" style="color: black">Map</span>
        <span v-else class="map-title">Satellite</span>
      </div>
      <l-map
        ref="map"
        style="z-index: 1"
        :zoom.sync="zoom"
        :center="center"
        class="elevator-ll-map"
      >
        <l-tile-layer
          v-if="satellite"
          url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
        />
        <l-tile-layer
          v-else
          url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
        />
        <v-marker-cluster ref="myCluster" :options="myMapSettings.clusterOptions">
          <l-marker
            v-for="(elevator, index) in elevatorList"
            :key="index"
            :lat-lng="elevator"
          >
          </l-marker>
        </v-marker-cluster>
      </l-map>
    </div>
    <router-link class="hidden-link" to="/map-ua">Map ua</router-link>
  </div>
</template>
<script>
// import { mapState, mapGetters } from 'vuex'
// import { EventBus } from '../../store/event-bus.js'
import axios from 'axios'
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import { divIcon as DivIcon, point as Point } from "leaflet";
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import 'leaflet/dist/leaflet.css';
// let Vue2LeafletMarkerCluster
// if (process.client) {
//   Vue2LeafletMarkerCluster = require('vue2-leaflet-markercluster')
// }
export default {
  name: 'Map',
  components: {
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    LMap,
    LTileLayer,
    LMarker
  },
  data () {
    return {
      zoom: 4,
      center: [55, 15],
      satellite: false,
      url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
      showParagraph: false,
      elevatorList: [],
      myMapSettings: {
        clusterOptions: {
          iconCreateFunction: cluster => {
            var childCount = cluster.getChildCount();
            var c = " my-marker-cluster-";
            if (childCount < 10) {
              c += "small";
            } else if (childCount < 100) {
              c += "medium";
            } else {
              c += "large";
            }

            return new DivIcon({
              html: "<div><span>" + childCount + "</span></div>",
              className: "marker-cluster" + c,
              iconSize: new Point(40, 40)
            });
          }
        }
      }
    }
  },
  computed: {},
  mounted () {
    // this.initMap()
    this.getData()
  },
  methods: {
    getData () {
      axios
          .get('/test_data.json')
          .then((response) => {
            this.elevatorList = response.data
            // this.getUkrData()
          })
    },
    // getUkrData () {
    //   axios
    //     .get('/test_data_ukraine.json')
    //     .then((response) => {
    //       this.elevatorList = this.elevatorList.concat(response.data)
    //     })
    // }
    // initMap () {
    //   const that = this
    //   let bounds
    //   this.$nextTick(() => {
    //     this.map = this.$refs.map.mapObject
    //     if (this.map) {
    //       this.map.on('moveend', function () {
    //         bounds = that.map.getBounds()
    //         that.$store.dispatch('elevator/elevator/inBounds', [])
    //         const boundsArr = []
    //         that.elevatorList.forEach((elevator) => {
    //           if (
    //             elevator.center[0] < bounds._northEast.lat &&
    //             elevator.center[0] > bounds._southWest.lat &&
    //             elevator.center[1] < bounds._northEast.lng &&
    //             elevator.center[1] > bounds._southWest.lng
    //           ) {
    //             boundsArr.push(elevator)
    //           }
    //         })
    //         that.$store.dispatch('elevator/elevator/inBounds', boundsArr)
    //       })
    //     }
    //   })
    // },
  }
}
</script>
<style lang="scss">
.map-wrap {
  position: relative;
  /*height: 250px;*/
  padding: 0;
  height: calc(100vh - 70px);
  .map-mode-btn {
    z-index: 3;
    position: absolute;
    left: 10px;
    bottom: 10px;
    height: 60px;
    width: 60px;
    /*border: 2px solid white;*/
    cursor: pointer;
    @media (max-width: 960px) {
      top: 10px;
    }
    .map-img {
      height: 60px;
      width: 60px;
      border: 2px solid white;
      border-radius: 5px;
    }
    .map-title {
      position: absolute;
      font-size: 12px;
      color: white;
      bottom: 3px;
      left: 6px;
    }
  }
  .elevator-ll-map {
    z-index: 2;
    position: absolute;
    height: calc(100vh - 70px);
  }
}

/*.leaflet-control-container {*/
  /*position: absolute;*/
  /*top: 80px;*/
  /*@media (max-width: 960px) {*/
    /*display: none;*/
  /*}*/
/*}*/

.marker-cluster {
    border-radius: 100px !important;
  div {
    width: 28px !important;
    height: 28px !important;
    margin: 6px !important;
    text-align: center !important;
    border-radius: 100px !important;
    line-height: 28px
  }
}


/* Small */
.my-marker-cluster-small {
  background-color: rgba(110,204,57,.3);
  div {
    background-color: rgba(110,204,57,.6);
  }
}

/* Medium */
.my-marker-cluster-medium {
  background-color: rgba(240,194,12,.3);
  div {
    background-color: rgba(240,194,12,.6)
  }
}

/* Large */
.my-marker-cluster-large {
  background-color: rgba(241,128,23,.3);
  div {
    background-color: rgba(241,128,23,.6)
  }
}
.elevators-component {
  position: relative;
}
.hidden-link {
  position: absolute;
  right: 0;
  bottom: -19px;
  color: transparent;
  background: transparent;
}
</style>
