<template>
  <div class="home">
    <div>
      <ElevatorsMapUA />
    </div>
  </div>
</template>

<script>
import ElevatorsMapUA from '@/components/ElevatorsMapUA.vue'
export default {
  name: 'MapUA',
  components: {
    ElevatorsMapUA
  }
}
</script>

<style>
.home {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
  /* height: 60vh; */
}
</style>