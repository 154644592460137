import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  'en': {
    about: 'About the Project',
    aboutUs1: 'Agroxy develops and runs ICT solutions to make agricultural supply chains more efficient.In this project, we are making our contribution to reducing hunger and carbon emission is the agricultural supply chain, by mapping out elements of agricultural storage infrastructure, using a combination of AI tools. Our project helps buyers to locate available commodities closer to them, reducing transportation costs and carbon emissions, while sellers gain wider market access.',
    aboutUs2: 'We also would like to encourage kind donors to use locally available food supplies to reduce hunger, particularly in Africa. This will help the local agricultural production and reduce carbon emission avoiding unnecessarily long-leg transportation.',
    aboutUs3: 'We invite owners of storage facilities to add more information about their facilities and owners of products stored at those facilities make it more accessible for buyers.',
    aboutUs4: 'We also would like to build trust in agricultural trade so all efforts will be made to verify the information supplied. We can’t guarantee the accuracy of information at this stage, so reasonable precautions need to be used when using this site. With time, the quality of the information will improve and trust level will increase.',
    aboutUs5: 'Please let us know if you spot any errors or mistakes.',
    aboutUs6: 'Finally, we would like to thank Alex Radchenko, a Computer Science student from St Andrew’s university for his gratuitous contribution in developing AI solution for locating of the items of agricultural supply chain infrastructure.',
    feeding: 'Feeding the world',
    goal1: 'End poverty in all its forms everywhere.',
    goal2: 'End hunger, achieve food security and improved nutrition and promote sustainable agriculture.',
    goal3: 'Ensure healthy lives and promote well-being for all at all ages.',
    goal4: 'Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.',
    goal5: 'Achieve gender equality and empower all women and girls.',
    goal6: 'Ensure availability and sustainable management of water and sanitation for all.',
    goal7: 'Ensure access to affordable, reliable, sustainable and modern energy for all.',
    goal8: 'Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all.',
    goal9: 'Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation.',
    goal10: 'Reduce inequality within and among countries.',
    goal11: 'Make cities and human settlements inclusive, safe, resilient and sustainable.',
    goal12: 'Ensure sustainable consumption and production patterns.',
    goal13: 'Take urgent action to combat climate change and its impacts.',
    goal14: 'Conserve and sustainably use the oceans, seas and marine resources for sustainable development.',
    goal15: 'Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss.',
    goal16: 'Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels.',
    goal17: 'Strengthen the means of implementation and revitalize the global partnership for sustainable development.',
    reducing: 'Reducing carbon emissions',
  },
  'ua': {
    about: 'Про проект',
    aboutUs1: 'Agroxy розробляє та використовує ІКТ-рішення, щоб зробити ланцюги поставок сільськогосподарської продукції більш ефективними. У цьому проекті ми робимо свій внесок у зменшення голоду та викидів вуглецю в ланцюжку поставок сільськогосподарської продукції, відображаючи елементи інфраструктури зберігання сільськогосподарської продукції за допомогою комбінації інструментів ШІ. Наш проект допомагає покупцям знаходити доступні товари ближче до них, зменшуючи витрати на транспортування та викиди вуглецю, а продавці отримують ширший доступ до ринку.',
    aboutUs2: 'Ми також хотіли б заохотити добрих донорів використовувати місцеві запаси їжі для зменшення голоду, особливо в Африці. Це допоможе місцевому сільськогосподарському виробництву та зменшить викиди вуглекислого газу, уникаючи непотрібно довгих перевезень.',
    aboutUs3: 'Запрошуємо власників складських приміщень додавати більше інформації про свої об’єкти, а власники продукції, що зберігається на цих об’єктах, роблять її доступнішою для покупців.',
    aboutUs4: 'Ми також хочемо зміцнити довіру до торгівлі сільськогосподарською продукцією, тому докладемо всіх зусиль для перевірки наданої інформації. Ми не можемо гарантувати точність інформації на даному етапі, тому під час використання цього сайту необхідно вживати розумних запобіжних заходів. З часом якість інформації покращиться, а рівень довіри підвищиться.',
    aboutUs5: 'Будь ласка, повідомте нас, якщо ви помітили будь-які помилки чи помилки.',
    aboutUs6: 'Нарешті, ми хотіли б подякувати Алексу Радченку, студенту комп’ютерних наук з Університету Сент-Ендрю, за його безоплатний внесок у розробку рішення штучного інтелекту для визначення місцезнаходження об’єктів інфраструктури ланцюга поставок сільськогосподарської продукції.',
    feeding: 'Нагодувати світ',
    goal1: 'Покінчити з бідністю у всіх її формах всюди.',
    goal2: 'Покінчити з голодом, забезпечити продовольчу безпеку та покращити харчування та сприяти сталому сільському господарству.',
    goal3: 'Забезпечення здорового життя та сприяння добробуту для всіх у будь-якому віці.',
    goal4: 'Забезпечити інклюзивну та справедливу якісну освіту та заохочувати можливості навчання протягом усього життя для всіх.',
    goal5: 'Досягніть гендерної рівності та розширте можливості всіх жінок і дівчат.',
    goal6: 'Забезпечити доступність та стале управління водою та санітарією для всіх.',
    goal7: 'Забезпечити доступ до доступної, надійної, стійкої та сучасної енергії для всіх.',
    goal8: 'Сприяти стійкому, інклюзивному та сталому економічному зростанню, повній і продуктивній зайнятості та гідній роботі для всіх.',
    goal9: 'Створюйте стійку інфраструктуру, сприяйте інклюзивній та сталий індустріалізації та сприяйте інноваціям.',
    goal10: 'Зменшення нерівності всередині та між країнами.',
    goal11: 'Зробіть міста та населені пункти інклюзивними, безпечними, стійкими та сталими.',
    goal12: 'Забезпечити стійкі моделі споживання та виробництва.',
    goal13: 'Вжити термінових заходів для боротьби зі зміною клімату та її наслідками.',
    goal14: 'Зберігайте та раціонально використовуйте океани, моря та морські ресурси для сталого розвитку.',
    goal15: 'Захищати, відновлювати та сприяти сталому використанню наземних екосистем, раціонально керувати лісами, боротися з опустелюванням, а також зупинити та повернути назад деградацію землі та зупинити втрату біорізноманіття.',
    goal16: 'Сприяти мирним та інклюзивним суспільствам для сталого розвитку, забезпечувати доступ до правосуддя для всіх і створювати ефективні, підзвітні та інклюзивні інституції на всіх рівнях.',
    goal17: 'Посилити засоби впровадження та активізувати глобальне партнерство для сталого розвитку.',
    reducing: 'Зменшення викидів вуглецю',
  },
  'ru': {
    about: 'О проекте',
    aboutUs1: 'Agroxy разрабатывает и внедряет решения на базе ИКТ, позволяющие повысить эффективность цепочек поставок сельскохозяйственной продукции. В этом проекте мы вносим свой вклад в сокращение масштабов голода и выбросов углекислого газа в цепочке поставок сельскохозяйственной продукции, нанося на карту элементы инфраструктуры хранения сельскохозяйственной продукции, используя комбинацию инструментов искусственного интеллекта. Наш проект помогает покупателям находить доступные товары ближе к ним, сокращая транспортные расходы и выбросы углекислого газа, а продавцы получают более широкий доступ к рынку.',
    aboutUs2: 'Мы также хотели бы призвать добрых доноров использовать местные запасы продовольствия для сокращения голода, особенно в Африке. Это поможет местному сельскохозяйственному производству и сократит выбросы углекислого газа, избегая излишне длинных перевозок.',
    aboutUs3: 'Приглашаем владельцев складов добавлять дополнительную информацию о своих объектах, а владельцев продукции, хранящейся на этих объектах, сделать ее более доступной для покупателей.',
    aboutUs4: 'Мы также хотели бы укрепить доверие к торговле сельскохозяйственной продукцией, поэтому мы приложим все усилия для проверки предоставленной информации. На данном этапе мы не можем гарантировать точность информации, поэтому при использовании этого сайта необходимо соблюдать разумные меры предосторожности. Со временем качество информации улучшится, а уровень доверия повысится.',
    aboutUs5: 'Пожалуйста, дайте нам знать, если вы заметили какие-либо ошибки или ошибки.',
    aboutUs6: 'Наконец, мы хотели бы поблагодарить Алекса Радченко, студента факультета компьютерных наук Сент-Эндрюского университета, за его безвозмездный вклад в разработку ИИ-решения для определения местоположения элементов инфраструктуры цепочки поставок сельскохозяйственной продукции.',
    feeding: 'Кормление мира',
    goal1: 'Покончить с нищетой во всех ее формах повсюду.',
    goal2: 'Покончить с голодом, добиться продовольственной безопасности и улучшения питания, а также содействовать устойчивому сельскому хозяйству.',
    goal3: 'Обеспечивать здоровый образ жизни и способствовать благополучию для всех в любом возрасте.',
    goal4: 'Обеспечить инклюзивное и справедливое качественное образование и продвигать возможности обучения на протяжении всей жизни для всех.',
    goal5: 'Достичь гендерного равенства и расширить права и возможности всех женщин и девочек.',
    goal6: 'Обеспечить доступность и устойчивое управление водными ресурсами и санитарией для всех.',
    goal7: 'Обеспечить доступ к доступной, надежной, устойчивой и современной энергии для всех.',
    goal8: 'Содействовать устойчивому, инклюзивному и устойчивому экономическому росту, полной и продуктивной занятости и достойной работе для всех.',
    goal9: 'Создавать устойчивую инфраструктуру, продвигать инклюзивную и устойчивую индустриализацию и стимулировать инновации.',
    goal10: 'Сокращение неравенства внутри стран и между ними.',
    goal11: 'Сделать города и населенные пункты инклюзивными, безопасными, жизнестойкими и устойчивыми.',
    goal12: 'Обеспечить устойчивые модели потребления и производства.',
    goal13: 'Примите срочные меры по борьбе с изменением климата и его последствиями.',
    goal14: 'Сохраняйте и устойчиво используйте океаны, моря и морские ресурсы для устойчивого развития.',
    goal15: 'Защищайте, восстанавливайте и содействуйте устойчивому использованию наземных экосистем, устойчивому управлению лесами, борьбе с опустыниванием, а также остановке и обращению вспять деградации земель и прекращению потери биоразнообразия.',
    goal16: 'Содействовать созданию мирных и инклюзивных обществ для устойчивого развития, обеспечивать доступ к правосудию для всех и создавать эффективные, подотчетные и инклюзивные институты на всех уровнях.',
    goal17: 'Укрепить средства реализации и активизировать глобальное партнерство в целях устойчивого развития.',
    reducing: 'Сокращение выбросов углекислого газа',
  },
  'sw': {
    about: 'Kuhusu mradi huo ',
    aboutUs1: 'Agroxy inakua na kuendesha suluhisho za ICT ili kufanya minyororo ya usambazaji wa kilimo iwe na ufanisi zaidi. Katika mradi huu, tunatoa mchango wetu katika kupunguza njaa na chafu ya kaboni ni ugavi wa kilimo, kwa kuchora vitu vya miundombinu ya uhifadhi wa kilimo, kwa kutumia mchanganyiko wa zana za AI . Mradi wetu husaidia wanunuzi kupata bidhaa zinazopatikana karibu nao, kupunguza gharama za usafirishaji na uzalishaji wa kaboni, wakati wauzaji wanapata soko pana. ',
    aboutUs2: 'Tunapenda pia kuhamasisha wafadhili wema kutumia chakula kinachopatikana nchini kupunguza njaa, haswa barani Afrika. Hii itasaidia uzalishaji wa kilimo wa ndani na kupunguza chafu ya kaboni kuzuia usafirishaji wa miguu mirefu bila lazima. ',
    aboutUs3: 'Tunakaribisha wamiliki wa vituo vya kuhifadhia kuongeza habari zaidi juu ya vituo vyao na wamiliki wa bidhaa zilizohifadhiwa kwenye vituo hivyo hufanya iweze kupatikana kwa wanunuzi. ',
    aboutUs4: 'Tunapenda pia kujenga imani katika biashara ya kilimo ili juhudi zote zitafanywa ili kudhibitisha habari iliyotolewa. Hatuwezi kuhakikisha usahihi wa habari katika hatua hii, kwa hivyo tahadhari zinazofaa zinapaswa kutumiwa wakati wa kutumia wavuti hii. Kwa wakati, ubora wa habari utaboresha na kiwango cha uaminifu kitaongezeka.',
    aboutUs5: 'Tafadhali tujulishe ikiwa unaona makosa yoyote au makosa.',
    aboutUs6: 'Mwishowe, tunapenda kumshukuru Alex Radchenko, mwanafunzi wa Sayansi ya Kompyuta kutoka chuo kikuu cha St Andrew kwa mchango wake wa bure katika kutengeneza suluhisho la AI kwa kupata vitu vya miundombinu ya ugavi wa kilimo.',
    feeding: 'Kulisha ulimwengu ',
    goal1: 'Kukomesha umaskini wa aina zote kila mahali.',
    goal2: 'Kukomesha njaa, kupata uhakika wa chakula na lishe bora na kukuza kilimo endelevu.',
    goal3: 'Hakikisha maisha ya afya na kukuza ustawi kwa wote katika umri wote.',
    goal4: 'Hakikisha elimu bora inayojumuisha na inayolingana na kukuza fursa za kujifunza maishani kwa wote.',
    goal5: 'Kufikia usawa wa kijinsia na kuwawezesha wanawake na wasichana wote.',
    goal6: 'Kuhakikisha uwepo na usimamizi endelevu wa maji na usafi wa mazingira kwa wote.',
    goal7: 'Hakikisha upatikanaji wa nishati nafuu, inayotegemewa, endelevu na ya kisasa kwa wote.',
    goal8: 'Kukuza ukuaji wa uchumi endelevu, shirikishi na endelevu, ajira kamili na yenye tija na kazi zenye staha kwa wote.',
    goal9: 'Jenga miundombinu thabiti, kukuza ushirikishwaji na maendeleo endelevu ya viwanda na kukuza uvumbuzi.',
    goal10: 'Kupunguza usawa ndani na kati ya nchi.',
    goal11: 'Fanya miji na makazi ya watu yawe jumuishi, salama, yenye uthabiti na endelevu.',
    goal12: 'Kuhakikisha matumizi endelevu na mifumo ya uzalishaji.',
    goal13: 'Chukua hatua za haraka kukabiliana na mabadiliko ya tabianchi na athari zake.',
    goal14: 'Kuhifadhi na kutumia kwa uendelevu bahari, bahari na rasilimali za baharini kwa maendeleo endelevu.',
    goal15: 'Linda, urejeshe na uendeleze matumizi endelevu ya mifumo ikolojia ya nchi kavu, dhibiti misitu kwa uendelevu, pambana na kuenea kwa jangwa, na ukomeshe na kurudisha nyuma uharibifu wa ardhi na kukomesha upotevu wa bayoanuwai.',
    goal16: 'Kukuza jamii zenye amani na umoja kwa maendeleo endelevu, kutoa ufikiaji wa haki kwa wote na kujenga taasisi zenye ufanisi, zinazowajibika na shirikishi katika ngazi zote.',
    goal17: 'Kuimarisha njia za utekelezaji na kuhuisha ushirikiano wa kimataifa kwa maendeleo endelevu.',
    reducing: 'Kupunguza uzalishaji wa kaboni ',
  },
  'fr': {
    about: 'À propos du projet ',
    aboutUs1: 'Agroxy développe et exploite des solutions TIC pour rendre les chaînes d\'approvisionnement agricoles plus efficaces. Dans ce projet, nous contribuons à réduire la faim et les émissions de carbone dans la chaîne d\'approvisionnement agricole, en cartographiant les éléments de l\'infrastructure de stockage agricole, en utilisant une combinaison d\'outils d\'IA . ',
    aboutUs2: 'Nous voudrions également encourager les généreux donateurs à utiliser les approvisionnements alimentaires disponibles localement pour réduire la faim, en particulier en Afrique. Cela aidera la production agricole locale et réduira les émissions de carbone en évitant les transports ',
    aboutUs3: 'Nous invitons les propriétaires d\'installations de stockage à ajouter plus d\'informations sur leurs installations et les propriétaires de produits stockés dans ces installations les rendent plus accessibles pour les acheteurs.',
    aboutUs4: 'Nous souhaitons également renforcer la confiance dans le commerce agricole afin que tous les efforts soient faits pour vérifier les informations fournies. Nous ne pouvons garantir l\'exactitude des informations à ce stade, des précautions raisonnables doivent donc être utilisées lors de l\'utilisation de ce site. Avec le temps, la qualité de l\'information s\'améliorera et le niveau de confiance augmentera.',
    aboutUs5: 'S\'il vous plaît laissez-nous savoir si vous repérez des erreurs ou des fautes.',
    aboutUs6: 'Enfin, nous tenons à remercier Alex Radchenko, un étudiant en informatique de l\'université de St Andrew pour sa contribution gratuite au développement d\'une solution d\'IA pour la localisation des éléments de l\'infrastructure de la chaîne d\'approvisionnement agricole.',
    feeding: 'Nourrir le monde ',
    goal1: 'Mettre fin à la pauvreté sous toutes ses formes partout dans le monde.',
    goal2: 'Mettre fin à la faim, parvenir à la sécurité alimentaire, améliorer la nutrition et promouvoir une agriculture durable.',
    goal3: 'Garantir une vie saine et promouvoir le bien-être de tous à tout âge.',
    goal4: 'Assurer une éducation de qualité inclusive et équitable et promouvoir les opportunités d’apprentissage tout au long de la vie pour tous.',
    goal5: 'Parvenir à l’égalité des sexes et autonomiser toutes les femmes et les filles.',
    goal6: 'Assurer la disponibilité et la gestion durable de l’eau et de l’assainissement pour tous.',
    goal7: 'Garantir l’accès de tous à une énergie abordable, fiable, durable et moderne.',
    goal8: 'Promouvoir une croissance économique soutenue, inclusive et durable, le plein emploi productif et un travail décent pour tous.',
    goal9: 'Construire des infrastructures résilientes, promouvoir une industrialisation inclusive et durable et favoriser l’innovation.',
    goal10: 'Réduire les inégalités au sein des pays et entre eux.',
    goal11: 'Rendre les villes et les établissements humains inclusifs, sûrs, résilients et durables.',
    goal12: 'Assurer des modes de consommation et de production durables.',
    goal13: 'Prendre des mesures urgentes pour lutter contre le changement climatique et ses impacts.',
    goal14: 'Conserver et utiliser durablement les océans, les mers et les ressources marines pour le développement durable.',
    goal15: 'Protéger, restaurer et promouvoir l’utilisation durable des écosystèmes terrestres, gérer durablement les forêts, lutter contre la désertification, stopper et inverser la dégradation des terres et mettre un terme à la perte de biodiversité.',
    goal16: 'Promouvoir des sociétés pacifiques et inclusives pour le développement durable, assurer l’accès de tous à la justice et construire des institutions efficaces, responsables et inclusives à tous les niveaux.',
    goal17: 'Renforcer les moyens de mise en œuvre et revitaliser le partenariat mondial pour le développement durable.',
    reducing: 'Réduire les émissions de carbone ',
  },
  'de': {
    about: 'Über das Projekt ',
    aboutUs1: 'Agroxy entwickelt und betreibt IKT-Lösungen, um landwirtschaftliche Lieferketten effizienter zu gestalten. In diesem Projekt leisten wir unseren Beitrag zur Reduzierung von Hunger und CO2-Emissionen in der landwirtschaftlichen Lieferkette, indem wir Elemente der landwirtschaftlichen Lagerinfrastruktur mit einer Kombination von KI-Tools abbilden . Unser Projekt hilft Käufern, verfügbare Waren in ihrer Nähe zu finden, wodurch Transportkosten und CO2-Emissionen reduziert werden, während Verkäufer einen breiteren Marktzugang erhalten. ',
    aboutUs2: 'Wir möchten auch freundliche Spender dazu ermutigen, lokal verfügbare Nahrungsmittel zu verwenden, um den Hunger, insbesondere in Afrika, zu reduzieren. Dies wird der lokalen landwirtschaftlichen Produktion helfen und die CO2-Emissionen reduzieren, wodurch unnötig lange Transportwege vermieden werden. ',
    aboutUs3: 'Wir laden Besitzer von Lagereinrichtungen ein, weitere Informationen über ihre Einrichtungen hinzuzufügen, und Besitzer von Produkten, die in diesen Einrichtungen gelagert werden, machen diese für Käufer leichter zugänglich. Wir möchten auch Vertrauen in den Agrarhandel aufbauen, daher werden alle Anstrengungen unternommen, um die bereitgestellten Informationen zu überprüfen. ',
    aboutUs4: 'Wir können die Richtigkeit der Informationen zu diesem Zeitpunkt nicht garantieren, daher müssen bei der Nutzung dieser Website angemessene Vorsichtsmaßnahmen getroffen werden. Mit der Zeit wird sich die Qualität der Informationen verbessern und das Vertrauensniveau wird steigen.',
    aboutUs5: 'Bitte teilen Sie uns mit, wenn Sie Fehler oder Fehler entdecken.',
    aboutUs6: 'Schließlich möchten wir Alex Radchenko, einem Informatikstudenten der St. Andrew\'s University, für seinen unentgeltlichen Beitrag zur Entwicklung einer KI-Lösung zum Auffinden der Elemente der landwirtschaftlichen Lieferketteninfrastruktur danken.',
    feeding: 'Die Welt ernähren ',
    goal1: 'Beenden Sie die Armut in all ihren Formen überall.',
    goal2: 'Den Hunger beenden, Ernährungssicherheit und eine bessere Ernährung erreichen und eine nachhaltige Landwirtschaft fördern.',
    goal3: 'Sorgen Sie für ein gesundes Leben und fördern Sie das Wohlbefinden aller Menschen jeden Alters.',
    goal4: 'Sorgen Sie für eine inklusive, gleichberechtigte und hochwertige Bildung und fördern Sie Möglichkeiten des lebenslangen Lernens für alle.',
    goal5: 'Erreichen Sie die Gleichstellung der Geschlechter und stärken Sie alle Frauen und Mädchen.',
    goal6: 'Gewährleistung der Verfügbarkeit und nachhaltigen Bewirtschaftung von Wasser und Sanitäranlagen für alle.',
    goal7: 'Den Zugang zu bezahlbarer, zuverlässiger, nachhaltiger und moderner Energie für alle sicherstellen.',
    goal8: 'Fördern Sie nachhaltiges, integratives und nachhaltiges Wirtschaftswachstum, produktive Vollbeschäftigung und menschenwürdige Arbeit für alle.',
    goal9: 'Bauen Sie eine widerstandsfähige Infrastruktur auf, fördern Sie eine integrative und nachhaltige Industrialisierung und fördern Sie Innovationen.',
    goal10: 'Reduzieren Sie die Ungleichheit innerhalb und zwischen Ländern.',
    goal11: 'Machen Sie Städte und menschliche Siedlungen inklusiv, sicher, widerstandsfähig und nachhaltig.',
    goal12: 'Sorgen Sie für nachhaltige Konsum- und Produktionsmuster.',
    goal13: 'Ergreifen Sie dringend Maßnahmen zur Bekämpfung des Klimawandels und seiner Auswirkungen.',
    goal14: 'Bewahren und nutzen Sie die Ozeane, Meere und Meeresressourcen nachhaltig für eine nachhaltige Entwicklung.',
    goal15: 'Landökosysteme schützen, wiederherstellen und ihre nachhaltige Nutzung fördern, Wälder nachhaltig bewirtschaften, Wüstenbildung bekämpfen, Landdegradation stoppen und umkehren und dem Verlust der biologischen Vielfalt Einhalt gebieten.',
    goal16: 'Fördern Sie friedliche und integrative Gesellschaften für eine nachhaltige Entwicklung, ermöglichen Sie allen den Zugang zur Justiz und bauen Sie wirksame, rechenschaftspflichtige und integrative Institutionen auf allen Ebenen auf.',
    goal17: 'Stärkung der Umsetzungsmittel und Neubelebung der globalen Partnerschaft für nachhaltige Entwicklung.',
    reducing: 'Reduzierung der CO2-Emissionen ',
  }
};

const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'es', // set fallback locale
  messages, // set locale messages
});

export default i18n;
